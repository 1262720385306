// rollover
var preLoadImg = new Object();
 
function initRollOvers(){
  $("img.imgover,input.imgover").each(function(){
    var imgSrc = this.src;
    var sep = imgSrc.lastIndexOf('.');
    var onSrc = imgSrc.substr(0, sep) + '_o' + imgSrc.substr(sep, 4);
    preLoadImg[imgSrc] = new Image();
    preLoadImg[imgSrc].src = onSrc;
    $(this).hover(
      function() { this.src = onSrc; },
      function() { this.src = imgSrc; }
    );
  });
}
$(function(){
  initRollOvers();
});


// smoothScroll

jQuery.easing.quart = function (x, t, b, c, d) {
  return -c * ((t=t/d-1)*t*t*t - 1) + b;
};

jQuery(document).ready(function(){
  jQuery('a[href*="#"]').click(function() {
    console.log("click");
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var $target = jQuery(this.hash);
      $target = $target.length && $target || jQuery('[name=' + this.hash.slice(1) +']');
      if ($target.length) {
        var targetOffset = $target.offset().top;
        jQuery('html,body').animate({ scrollTop: targetOffset }, 1200, 'quart');
        return false;
      }
    }
  });

});

// slide btn

$(function(){
  $(".slideBtn").each(function(index,element){
      $(element).click(function(){
        $(this).toggleClass('on');
        $(element).next(".slidePanel").slideToggle(300);
        });
      });

  // ハッシュがあるときはオープン
  if(window.location.hash.length>0){
    $(window.location.hash).toggleClass('on').next(".slidePanel").toggle();
  }
});

// gNav settings

$(function(){
  var winW = $(window).width();
  var winH = $(window).height();
  var header = $('#header');
  var gNav = $("#gnav");
  var menuBtn = $("#menuBtn");
  var navUl = gNav.find("ul");

  var flag = 'close';

  menuBtn.click(function(){
    if( flag == 'close' ){
      $(this).find("use").attr('xlink:href', '#svg_close');
      navUl.fadeIn(300);
      header.addClass('open');
      flag = 'open';
    } else {
      $(this).find("use").attr('xlink:href', '#svg_menu');
      navUl.fadeOut(300);
      header.removeClass('open');
      flag = 'close';
    }
  });
});

// current

$(function(){
  var gNavItem = $("#gnav .gnav__body__item__inner");

  var local_path_arr = $(location).attr('pathname').split('/');
  var local_dir = local_path_arr[1];

  gNavItem.each(function(){
    var href_path = $(this).attr("href");
    var href_path_arr = href_path.split('/');
    var href_dir = href_path_arr[1];
    
    if(href_dir == local_dir){
      $(this).addClass('current');
    } else {
      $(this).removeClass('current');
    }
  });

});